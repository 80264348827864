.verifyForm{
    margin: 0 auto;
    width: 300px;
    margin-top: 15px;
}
.verifyForm .form-control{
   height: 35px;
}
.verifyButton{
    min-width: 120px;
    padding: 4px 9px;
}

.verifyAccountContainer{
    background:#f2f2f2; padding-top:50px; font-family:'Open Sans', sans-serif; height: 100vh;
}
.verifyAccountDiv{
    margin:auto; width:600px; background:#fff; color:#000;
}
.verifyAccountHeader{
    padding:10px; text-align:center; border-bottom:1px solid #eee; background: #733ca6;  margin-bottom: 25px;
}
.verifyAccountHomeButton{
    margin-top: 20px;padding: 11px 30px; background:#79c022;  color:#fff; text-transform:uppercase; text-decoration:none; font-size:16px; font-weight:600; border-radius: 30px;
}
.verifyAccountHomeButton:hover{background:#733ca6;color:#fff;}
