.custom-time .bmd-form-group .form-control{
    margin-top: -17px;
    /* background: #fafafa; */
    height: auto;
    padding: 15px 10px;
    /* border: 1px solid #cccccc; */
    /* box-shadow: none; */
    /* height: 50px; */
    border-bottom: 0;
    background-image: none;
}
.custom-time{
    padding: 17px 0!important;
}