.uploadImage{
    display: inline-grid;
}
.service-list-title{
    color:#3f4040;
}
.service-list-title:hover{
    color:#009688;
}
.map{
    height: 400px;
}
#map{
    height:400px;
    position: relative;

}

#pac-input {
    padding: 0 40px;
    background: white;
    height: 50px;
    left: 0 !important;
    box-shadow: 0 0 25px 5px #eee !important;
    z-index: 999999 !important;
    margin: auto;
    margin: 0 auto;
    border-radius: 4px;
    margin-top: 10px;
    width: 94%;
    margin-left: 3%;
    border: 1px solid lightgray;
}
.map-div{
    position: relative;;
}
.pac-container{
    z-index: 99999;
}
.pt-18{
    padding-top:18px;
}
.right-inner-addon.date.datepicker {
    max-width: 100%;
}
.date-time-sec {
    display: block;
}
.date-time-sec .form-control{
    text-align:left;
}
.time-fliter-sec .form-control.custom-form{
    padding: 0 0 0 30px;
}
